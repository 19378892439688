import React, { useCallback, useState } from "react";
// import { useDispatch } from "react-redux";
// import { setSemanticReminder } from "../modules/semantic/actions";
import classnames from "classnames";
import styled from "styled-components";

const MenuOptionsWrapper = styled.div`
  /* width: 148px; /* Width of the box; comment it to make it self-adjusting */
  border-radius: 11.28px; /* Corner curvature */
  background-color: white; /* Background color of the box */
  box-shadow: 0 0 3.4496px rgba(0, 0, 0, 0.2); /* Shadow with 20% opacity and 3.4496px blur */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 8px 13px; /* padding for better text placement */
`;
const MenuOption = styled.div`
  font-family: sans-serif; /* Use sans-serif font */
  font-size: 8pt; /* 10pt font size .. or 8 15*/
  line-height: 15pt; /* 19pt interline spacing */
  cursor: pointer;
  width: 100%;
  text-align: left;
`;
const HorizontalLine = styled.div`
  width: 100%; /* Make the line span the full width */
  height: 0.5px; /* Set the height of the line */
  background-color: #b8b8b8; /* Set the color to light gray */
  margin: 2px 0; /* Optional: Add some margin for spacing */
`;

const translations = {
  "en-GB": {
    r1h: "Remind me in 1 hour",
    r2h: "Remind me in 2 hours",
    r4h: "Remind me in 4 hours",
    r1d: "Remind me in 1 day",
    close: "Close",
  },
  "it-IT": {
    r1h: "Ricordamelo tra 1 ora",
    r2h: "Ricordamelo tra 2 ore",
    r4h: "Ricordamelo tra 4 ore",
    r1d: "Ricordamelo tra 1 giorno",
    close: "Chiudi",
  },
  "fr-FR": {
    r1h: "Rappelez-moi dans 1 heure",
    r2h: "Rappelez-moi dans 2 heures",
    r4h: "Rappelez-moi dans 4 heures",
    r1d: "Rappelez-moi dans 1 jour",
    close: "Fermer",
  },
};

const getTranslation = (locale = "en-GB", labelkey) => {
  return translations[locale]?.[labelkey] ?? "";
};

const SemMenu = ({ showX, silent, locale, flowId, template, handleClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  // const dispatch = useDispatch();

  const handleMenuItemClick = useCallback((optionId) => {
    let reminderObj = null;
    if (optionId !== "close") {
      const value = menuOptions.find((e) => e.id === optionId).value;
      reminderObj = {
        flowId,
        currentTimestamp: String(Date.now()),
        delayms: value,
      };
      // setSemanticReminder goes in race condition with handleClose; if you use handleClose, set reminder from there
      // dispatch(setSemanticReminder(reminderObj));
    }
    // in any case, close
    handleClose({ reminderObj });
    setIsOpen(false);
  }, []);

  const reminderMenuOptions = [
    {
      value: 3600000, //ms
      id: "r1h",
      labelkey: "r1h",
    },
    {
      value: 7200000,
      id: "r2h",
      labelkey: "r2h",
    },
    {
      value: 14400000,
      id: "r4h",
      labelkey: "r4h",
    },
    {
      value: 86400000,
      id: "r1d",
      labelkey: "r1d",
    },
  ];
  let menuOptions = [];
  if (!silent) {
    menuOptions = [...reminderMenuOptions];
  }
  if (showX)
    menuOptions.push({
      value: "",
      id: "close",
      labelkey: "close",
    });
  // Note: reusing classes "closeBtn", "exit" from templates also for menu button
  return (
    <>
      <button
        id="close"
        className={classnames("closeBtn", "exit")}
        aria-label="Menu"
        onClick={toggling}
      >
        <span id="menuBtnContent"></span>
      </button>
      {isOpen && (
        <MenuOptionsWrapper className="menuOptionsWrapperPos">
          {menuOptions.map((option, index) => (
            <span key={option.id} style={{ width: "100%" }}>
              <MenuOption onClick={() => handleMenuItemClick(option.id)}>
                {getTranslation(locale, option.labelkey)}
              </MenuOption>
              {index !== menuOptions.length - 1 && <HorizontalLine />}
            </span>
          ))}
        </MenuOptionsWrapper>
      )}
    </>
  );
};

export default SemMenu;
