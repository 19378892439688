import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import rehypeRaw from "rehype-raw";
import {
  selectInitialProps,
  selectSemantic,
  selectQueryProps,
} from "../modules/semantic/selectors";
// import HiLogoIcon from "./icons/HiLogoIcon";
import CloseIcon from "./icons/CloseIcon";
import classnames from "classnames";
import textToHtml from "../util/textToHtml";
import ReactMarkdown from "react-markdown";
import CTAButton from "./CTAButton";
import Dropdown from "./Dropdown";
import styled, { css, createGlobalStyle } from "styled-components";
import { trackAnalytics, attributeGamePoints } from "../modules/config/actions";
import { closeSemantic, ctaClick } from "../modules/semantic/actions";
import useButtonProps from "../hooks/useButtonProps";
import useComponentLoaded from "../hooks/useComponentLoaded";
import { selectConfig } from "../modules/config/selectors";
import { ShakeAnimationCSS } from "./animations";
import SemMenu from "./SemMenu";
import { tmpSemanticTemplates } from "./tmpTemplates";
import {
  LEGACY_TEMPLATEFAMILY_ID,
  LEGACY_SEMANTICTEMPLATE_ID,
  CARD13A,
} from "../constConfig/config.js";
import templatePreProcessStyles from "../util/templatePreProcessStyles";

const Container = styled.div`
  ${({ animation }) => (animation ? ShakeAnimationCSS : "")}
`;

const provideSemanticStyleObject = function (
  template,
  image,
  templateColors,
  templateFonts,
  backgrounds
) {
  // preprocessing to templateCard

  // fonts, css colors, svg colors
  templatePreProcessStyles(
    template,
    templateColors,
    templateFonts,
    "semantic",
    image,
    backgrounds
  );

  return template.semanticStyle;
};

const SemanticStyles = createGlobalStyle(
  ({ template, image, templateColors, templateFonts, backgrounds }) => {
    return provideSemanticStyleObject(
      template,
      image,
      templateColors,
      templateFonts,
      backgrounds
    );
  }
);

const Semantic = ({ type, animation = true } = {}) => {
  const {
    text,
    cta1Text,
    cta2Text,
    title,
    dropdownData,
    image,
    flowId,
    journeyId,
    journey,
    templateFamilyId,
    semanticTemplateId,
    templateColors,
    templateFonts,
    images,
    showX,
    isRemindMeLaterEnabled,
  } = useSelector(selectSemantic);

  const dispatch = useDispatch();
  const [ctaDisabled, setCtaDisabled] = useState(false);
  const { logo, width, height, leafVersion } = useSelector(selectInitialProps);
  const { source, preview, silent } = useSelector(selectConfig);
  const { anchor } = useSelector(selectQueryProps);

  if (templateFonts && templateFonts.ctafontsize) {
    // auto assign this field; those cta have different defaults and variables in renderer, but content dashboard side there is just one var
    templateFonts.doublectafontsize = templateFonts.ctafontsize;
  }

  // use the custom template if:
  // not the legacy template family and a semanticTemplateId is specified and available
  const familyId = templateFamilyId
    ? templateFamilyId
    : LEGACY_TEMPLATEFAMILY_ID;

  const cardTemplate =
    familyId && semanticTemplateId && tmpSemanticTemplates?.[semanticTemplateId]
      ? semanticTemplateId
      : LEGACY_SEMANTICTEMPLATE_ID;

  const customSemanticTemplate = tmpSemanticTemplates?.[cardTemplate] ?? null;

  const anglePosition = useMemo(() => {
    switch (anchor) {
      case "top-left":
      case "center-left":
      case "bottom-left":
        return "topLeft";
      case "top-center":
      case "screen_center":
      case "bottom-center":
      case "top-right":
      case "center-right":
        return "topRight";
      default:
        return "";
    }
  }, [anchor]);

  const getButtonProps = useButtonProps();

  const handleCTAClick = useCallback(
    (e) => {
      const { target } = e;
      if (target) {
        setCtaDisabled(true);
        const {
          ctaType,
          ctaTarget,
          ctaValue,
          shouldOpenInNewWindow,
          shouldAddAuthParams,
          buttonName,
        } = getButtonProps(target);
        let action = `${buttonName}-clicked`;

        if (source === "diary") {
          action = `${action}-diary`;
        }

        dispatch(trackAnalytics({ action }));
        dispatch(attributeGamePoints({ action }));
        dispatch(
          ctaClick({
            ctaType,
            ctaTarget,
            ctaValue,
            shouldOpenInNewWindow,
            shouldAddAuthParams,
          })
        );
      }
    },
    [setCtaDisabled, getButtonProps, dispatch, source]
  );

  const actions = useMemo(() => {
    return (
      <>
        {cta1Text && (
          <CTAButton
            className={classnames("cta-button-default", {
              disabled: ctaDisabled,
            })}
            disabled={ctaDisabled}
            onClick={handleCTAClick}
            index="first"
            color="secondary"
          >
            {cta1Text}
          </CTAButton>
        )}
        {cta2Text && (
          <CTAButton
            className={classnames("cta-button-default", {
              disabled: ctaDisabled,
            })}
            disabled={ctaDisabled}
            onClick={handleCTAClick}
            index="second"
            color="secondary"
          >
            {cta2Text}
          </CTAButton>
        )}
      </>
    );
  }, [ctaDisabled, handleCTAClick, cta1Text, cta2Text]);

  const useComponentLoadedElementId = "semantic-root";
  const useComponentLoadedHistoryMeta =
    type === "PRIME"
      ? {
          flowId,
          journeyId,
          journeyName: journey?.name,
          interactionOn: null,
        }
      : {
          flowId,
          journeyId,
          journeyName: journey?.name,
        };

  const dropdownitemheight = customSemanticTemplate?.customVars?.rootCssVars
    ?.dropdownitemheight
    ? customSemanticTemplate.semanticStyle?.[
        customSemanticTemplate?.rootCssSelector
      ]?.[customSemanticTemplate.customVars.rootCssVars.dropdownitemheight]
      ? parseFloat(
          customSemanticTemplate.semanticStyle[
            customSemanticTemplate.rootCssSelector
          ][customSemanticTemplate.customVars.rootCssVars.dropdownitemheight]
        )
      : null
    : null;
  useComponentLoaded({
    width,
    height,
    elementId: useComponentLoadedElementId,
    semanticsHistoryMeta: useComponentLoadedHistoryMeta,
    // 1 dropdown element has the placeholder
    // -10px is arbitrary, just to have a bit less space below the tip
    additionalVerticalSpace:
      Array.isArray(dropdownData) &&
      dropdownData.length > 0 &&
      dropdownitemheight &&
      (dropdownData.length > 5 ? 5 : dropdownData.length) * dropdownitemheight -
        10,
  });

  const handleClose = useCallback(
    ({ reminderObj }) => {
      dispatch(closeSemantic({ reminderObj }));
    },
    [dispatch]
  );

  return (
    <Container id="semantic-root" animation={animation && !preview}>
      <span id="extra1"></span>
      <span id="extra2"></span>
      <span id="extra3"></span>
      <div className={classnames("card", anglePosition)}>
        {/* {cardTemplate === LEGACY_SEMANTICTEMPLATE_ID ? (
          logo ? (
            <img src={logo} alt={logo} height={20} className="logo" />
          ) : (
            <HiLogoIcon width={20} height={20} className="logo" />
          )
        ) : (
          <span className="logo"></span>
        )} */}
        {/* do not display close button in diary */}
        {!isRemindMeLaterEnabled || !flowId || silent ? (
          <button
            id="close"
            className={classnames("closeBtn", "exit")}
            aria-label="Close"
            onClick={() => handleClose({})}
            style={{
              visibility:
                (!source || (source && source !== "diary")) && showX
                  ? "visible"
                  : "hidden",
            }}
          >
            {cardTemplate === LEGACY_SEMANTICTEMPLATE_ID ? (
              <CloseIcon width={11} height={11} fill="#8F9195" />
            ) : (
              <span id="closeBtnContent"></span>
            )}
          </button>
        ) : (
          <SemMenu
            showX={showX && source !== "diary"} // ensure in Diary there is no close option
            silent={silent}
            locale={journey.locale}
            flowId={flowId}
            template={cardTemplate}
            handleClose={({ reminderObj }) => handleClose({ reminderObj })}
          />
        )}
        {cardTemplate === LEGACY_SEMANTICTEMPLATE_ID && Boolean(image) && (
          <div className="media" />
        )}
        {cardTemplate === CARD13A && (
          <div className="mediaSVG">
            <span id="extra4"></span>
            <span id="extra5"></span>
            {Boolean(image) && <div className="media" />}
          </div>
        )}
        {cardTemplate !== LEGACY_SEMANTICTEMPLATE_ID &&
          cardTemplate !== CARD13A &&
          Boolean(image) &&
          customSemanticTemplate?.userCustomizable?.images?.image?.allowed ===
            true && <div className="media" />}
        <div className={classnames("content", { noText: !text })}>
          <h2 className="title">
            <ReactMarkdown
              className="markdown"
              rehypePlugins={[rehypeRaw]}
              children={textToHtml(title)}
            />
          </h2>
          {Boolean(text) && (
            <div className="text">
              <ReactMarkdown
                className="markdown"
                rehypePlugins={[rehypeRaw]}
                children={textToHtml(text)}
              />
            </div>
          )}
        </div>
        {actions && (
          <>
            {Array.isArray(dropdownData) &&
              // 1 element is for placeholder;
              // avoid displaying in case there is no real selectable value
              dropdownData.length > 1 && (
                <div id="custom-select">
                  <Dropdown
                    setCtaDisabled={setCtaDisabled}
                    dropdownData={dropdownData}
                    template={cardTemplate}
                  />
                </div>
              )}
            <div
              className={classnames("actions", {
                isDouble: cta1Text && cta2Text,
              })}
            >
              {actions}
            </div>
          </>
        )}
      </div>
      <SemanticStyles
        image={image}
        backgrounds={images}
        type={type}
        leafVersion={leafVersion}
        preview={preview}
        rootHeight={height}
        rootWidth={width}
        template={customSemanticTemplate}
        templateColors={templateColors}
        templateFonts={templateFonts}
      />
    </Container>
  );
};

export default Semantic;
