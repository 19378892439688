import { scopedCreators } from "../../store/utils/createAction";

const [createAction] = scopedCreators("semantic");

export const setInitialProps = createAction("SET_INITIAL_PROPS");
export const setQueryProps = createAction("SET_QUERY_PROPS");
export const getSemantic = createAction("GET_SEMANTIC");
export const setSemantic = createAction("SET_SEMANTIC");

export const primeMiniClose = createAction("PRIME_MINI_CLOSE");

export const primePostponeClick = createAction("PRIME_POSTPONE_CLICK");

export const ctaClick = createAction("CTA_CLICK");

export const feedbackClick = createAction("FEEDBACK_CTA_CLICK");

export const closeSemantic = createAction("CLOSE");
export const resetClose = createAction("RESET_CLOSE");
export const setClose = createAction("SET_CLOSE");

export const setDropdownValue = createAction("SET_DROPDOWN_VALUE");

export const setSemanticAsLoaded = createAction("SET_SEMANTIC_AS_LOADED");

export const setSemanticReminder = createAction("SET_SEMANTIC_REMINDER");
